import * as React from "react";
import "../styles/style.css";

// markup
const TreatmentPage = () => {
  const toggleNav = () => {
    const mobileNav = document.getElementById("mobileNav");
    mobileNav.classList.toggle("visible");
  };
  return (
    <main>
      <title>Alexandra Rusu - Despre mine</title>
      <header>
        <span>Dr. Alexandra Rusu</span>
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
        <button className="menu-trigger" onClick={toggleNav}>
          Menu
        </button>
      </header>
      <div className="mobile-nav" id="mobileNav">
        <nav>
          <a href="/">Prima pagina</a>
          <a href="/about">Despre mine</a>
          <a href="/cases">Cazuri</a>
          <a href="/treatment">Tratamente și tarife</a>
          <a href="/contact">Contact</a>
        </nav>
      </div>
      <section className="treatment-page">
        <h1>Tratamente</h1>

        <div className="treatment-list">
          <div className="item">
            <h3>Aparate mobilizabile copii</h3>
            <p>
              <span>începând de la</span> 1000 RON
            </p>
          </div>
          <div className="item">
            <h3>Aparate fixe - bracketsi metalici</h3>
            <p>
              <span>începând de la</span> 2000 RON / arcada
            </p>
          </div>
          <div className="item">
            <h3>Aparate fixe - bracketsi safir</h3>
            <p>
              <span>începând de la</span> 3500 RON / arcada
            </p>
          </div>
          <div className="item">
            <h3>Tratament cu alignere</h3>
            <p>
              <span>începând de la</span> 4500 RON
            </p>
          </div>
          <p className="subtitle">
            Costul tratamentelor se poate achita in rate.
          </p>
        </div>
      </section>
      <footer>
        <div className="col">
          <h3>
            Alexandra Rusu
            <br />
            <span>— medic specialist ortodontie</span>
            <br />
            <small>© 2021</small>
          </h3>
        </div>
        <div className="col">
          <a href="/contact">Contact</a>
        </div>
      </footer>
    </main>
  );
};

export default TreatmentPage;
